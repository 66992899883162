import React from 'react';
import { Router, Route, Switch, Redirect} from 'react-router-dom';
import {Helmet} from 'react-helmet';

import AOS from 'aos'
import home from "../Page/home";
import Footer from "../Page/Footer";
import Header from "../Page/Header";
import Common from "../Page/Common";
import { history } from '../_helpers';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "../assets/vendor/aos/aos.css"
import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "../assets/vendor/glightbox/css/glightbox.min.css";
import "../assets/vendor/remixicon/remixicon.css";
import "../assets/vendor/swiper/swiper-bundle.min.css";
import "../assets/css/slide.css";
import "../assets/css/style.css";

import AboutMe from "../Page/Pages/AboutMe";
import Faqs from "../Page/Pages/Faqs";
import Portfolio from "../Page/Pages/Portfolio";
import Features from "../Page/Pages/Features";
import Pricing from "../Page/Pages/Pricing";
import Services from "../Page/Pages/Services";
import Certificate from "../Page/Pages/Certificate";
import Contact from "../Page/Pages/Contact";
import WebDesignerInGurgaon from "../Page/Services/WebDesignerInGurgaon";
import WebDeveloperInGurgaon from "../Page/Services/WebDeveloperInGurgaon";
import GraphicDesignerinGurgaon from "../Page/Services/GraphicDesignerinGurgaon";
import FreelanceSeoServicesInGurugram from "../Page/Services/FreelanceSeoServicesInGurugram";
import PPCServicesInGurgaon from "../Page/Services/PPCServicesInGurgaon";
import FreelanceJobsInGurgaon from "../Page/Services/FreelanceJobsInGurgaon";
import FreelanceWorkInGurgaon from "../Page/Services/FreelanceWorkInGurgaon";
import SocialMediaMarketingFreelancersInGurugram from "../Page/Services/SocialMediaMarketingFreelancersInGurugram";
import DigitalMarketingFreelancerInBangalore from "../Page/Services/DigitalMarketingFreelancerInBangalore";

AOS.init();
function App() {
    return (
        <div>
 
                    <Router history={history}>
               <Header />
                     <Helmet>
  <title>Pankaj | Website Designing Company in Gurgaon</title>
  <meta name="description" content="Website designing company in gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Us, one of Best Web Design and Development Companies in India."/>
  <meta name="keywords" content="website designing company in gurgaon, website developer in gurgaon, website development, web development company in gurgaon, website maker in gurgaon, website design company in gurgaon, pankaj web designer, pankj, freelance website designer, website designers in gurgaon, website developer near me, web designing company in gurgaon,
"/>
  <meta property="og:type" content="website" />
<meta property="og:locale" content="en_US" />
<meta property="og:title" content="Pankaj | Website designing company in gurgaon | website development company in gurgaon" />
<meta property="og:description" content="Website designing company in gurgaon,Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Us, one of Best Web Design and Development Companies in India." />
<meta property="og:site_name" content="Pankaj - Website designing company in gurgaon | website development company in gurgaon" />
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:site" content="@pankj" />
<meta name="twitter:title" content="Pankaj | Website designing company in gurgaon | website development company in gurgaon" />
<meta name="twitter:description" content="Website designing company in gurgaon, Delhi, Noida Your company’s website is the first line of inquiry for new customers, so why not Web design services from Us, one of Best Web Design and Development Companies in India." />
<meta name="Distribution" content="Global" />
<meta name="Robots" content="follow, index, all" />
<meta name="googlebot" content="index, follow" />
<meta http-equiv="Expires" content="never" />
<meta name="document-type" content="public" />
<meta name="revisit-after" content="daily" />
<meta name="country" content="India" />
<meta name="Language" content="English" />
<meta name='rating' content="general" />
<link rel="canonical" href="https://www.pankj.com" />
<meta name="google-site-verification" content="v1NPNyoW9kqW8_e8pf1FrUJSlfjVKw0tdsA4cs6gJ-8" />
<meta name="author" content="pankj.com" />
<meta name="theme-color" content="#000" />

        </Helmet>
                        <Switch>
                            <Route exact path= {["/","/home", "/index.html"]} component={home} />
                            <Route path="/about-me" component={AboutMe} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/certificate" component={Certificate} />
                            <Route path="/faqs" component={Faqs} />
                            <Route path="/portfolios" component={Portfolio} />
                            <Route path="/pricing" component={Pricing} />
                            <Route path="/services" component={Services} />
                            <Route path="/features" component={Features} />


                            <Route path="/web-designer-in-gurgaon" component={WebDesignerInGurgaon} />
                            <Route path="/web-developer-in-gurgaon" component={WebDeveloperInGurgaon} />
                            <Route path="/graphic-designer-in-gurgaon" component={GraphicDesignerinGurgaon} />
                            <Route path="/freelance-seo-services-in-gurugram" component={FreelanceSeoServicesInGurugram} />
                            <Route path="/ppc-services-in-gurgaon" component={PPCServicesInGurgaon} />
                            <Route path="/freelance-jobs-in-gurgaon" component={FreelanceJobsInGurgaon} />
                            <Route path="/freelance-work-in-gurgaon" component={FreelanceWorkInGurgaon} />
                            <Route path="/social-media-marketing-freelancers-in-gurugram" component={SocialMediaMarketingFreelancersInGurugram} />
                            <Route path="/digital-marketing-freelancer-in-bangalore" component={DigitalMarketingFreelancerInBangalore} />



                            <Redirect from="*" to="/" />
                              </Switch>
                       
                   <Footer />
                   <Common />
                    </Router>
                </div> 
    );
}
export { App };
