import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item} from 'react-photoswipe-gallery'
import MyGallery from "../portfolio-gallery/myGallery";
function Items({data}){
    return (
        <div className="row ">


   <Gallery>

    {
          data.map((value)=>{
           const {id, image, title, description} = value;
           return (

   <div className="col-lg-4 col-md-6 my-3 portfolio-item filter-app" key={id}>
   
     <div className="portfolio-wrap">
      
 <Item
      original={image}
      thumbnail={image}
      width="1024"
      height="768"
    >
      {({ ref, open }) => (
      <>
        <img ref={ref} onClick={open} src={image} />
<div className="portfolio-info">
                <h4>{title}</h4>
                <p>{description}</p>
                <div className="portfolio-links">
                    
                  <a ref={ref} onClick={open} title={title}><i className="bi bi-plus"></i></a>
                
                  <a href="portfolio-details.html" title={title}><i className="bi bi-link"></i></a>
                </div>
                
              </div>
              </>
      )}

    </Item>

   </div>
   </div>
   
         )
          })


 

        }

  </Gallery>



</div>

    )
}

export default Items;