function Tabs({filterCategory, tabsData}){
    return(
        <>

 <div className="text-center my-4">
 {
 tabsData.map((category, index)=>{
      return (
         <li className="filter-active1" onClick={()=> filterCategory(category)} key={index}>{category}</li>
      )
 })
 }


</div>
        </>
    )
}

export default Tabs;