import { Link } from "react-router-dom";

function closeNav() {
  document.getElementById("mySidebar").style.width = "0";
}
function ValidateContact(){

          var name = document.getElementById("name");
          var phone = document.getElementById("phone");
          var email = document.getElementById("email");
           var sms = document.getElementById("sms").value;

            if (name.value === "") {
                alert("Please enter your Name")
                name.focus();
                return false;
            }
                else if (phone.value === "") {
                alert("Please enter your Phone No");
                phone.focus();
                return false;
            }
             else if (email.value === "") {
                alert("Please enter your Email-ID");
                email.focus();
                return false;
            }
            else if (email.value !== "" && IsValidEmail(email.value) === false) {
                alert("Please enter a valid Email-ID");
                email.focus();
                return false;
            }
             else if (sms === "") {
                alert("Please enter your Message");
                sms.focus();
                return false;
            }
             else if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(sms) && sms!=='') {
                alert("Oops! Message field should not contain any URL.");
                return false;
            }

  }
   function IsValidEmail(strEmail) {
            if (strEmail.length > 0) {
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(strEmail)) {
                    return (true);
                }
                return (false);
            }
        }

function Common() {
    return (
        <div>
       
<div className="sideleft"><ul>
  <li><a target="_blank" href="https://www.linkedin.com/in/pankj/"><i className="bi bi-linkedin"></i></a></li>
<li><a target="_blank" href="https://api.whatsapp.com/send?phone=917838704822&text=Hello%20Pankaj"><i className="bi bi-whatsapp" aria-hidden="true"></i></a></li>
  <li><a target="_blank" href="https://twitter.com/pankajsingh642"><i className="bi bi-twitter"></i></a></li>
  <li><a target="_blank" href="https://www.facebook.com/pankaj171cse"><i className="bi bi-facebook"></i></a></li>
  <li><a target="_blank" href="https://www.instagram.com/pankajsingh642"><i className="bi bi-instagram"></i></a></li>
  <li><a target="_blank" href="https://in.pinterest.com/pankajsingh642/"><i className="bi bi-pinterest"></i></a></li>
  <li><a target="_blank" href="tel:+917838704822"><i className="bi bi-phone"></i></a></li>
</ul></div>

<div className="floating_btn">
<a target="_blank" href="https://api.whatsapp.com/send?phone=917838704822&amp;text=Hello Pankaj, I am looking Website. Please share me details.">
<div className="contact_icon">
    <img src="assets/img/whatsapp.png" alt="Whatsapp Support" /> </div> </a>
   </div>
        </div>
        
    );
}
export default Common;
