import MyGallery from "../portfolio-gallery/myGallery";

export default function portfolios() {
  return (
    <>
 



    <MyGallery />

    </>
  );
}
