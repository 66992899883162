export default function services() {
  return (
    <>
 
    <section id="services" className="services">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Services</h2>
          <p>Our Popular Services</p>
        </header>

        <div className="row gy-4">

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div className="service-box blue">
              <i className="ri-discuss-line icon"></i>
              <h3>Website Design and Development</h3>
              <p>A well-designed and user-friendly website is crucial for businesses to create a positive online impression and provide a seamless user experience. Website design and development services include creating responsive and visually appealing websites, optimizing website speed and performance, and ensuring smooth navigation and functionality. A well-designed website helps businesses establish their online presence, engage with users, and drive conversions.</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-box orange">
              <i className="ri-discuss-line icon"></i>
              <h3>Content Marketing</h3>
              <p>Content marketing focuses on creating valuable and relevant content that resonates with the target audience. It includes creating blog posts, articles, videos, infographics, and other types of content that provide value to users. Content marketing helps businesses establish thought leadership, engage with their audience, and drive organic traffic to their website through SEO.</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
            <div className="service-box green">
              <i className="ri-discuss-line icon"></i>
              <h3>Email Marketing</h3>
              <p>Email marketing involves creating and sending targeted email campaigns to a business's subscribers or customers. It includes strategies such as newsletter campaigns, promotional offers, personalized content, and customer retention campaigns. Email marketing is a highly effective way to nurture leads, engage with customers, and drive repeat purchases.</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div className="service-box red">
              <i className="ri-discuss-line icon"></i>
              <h3>Social Media Marketing</h3>
              <p>Social media marketing involves creating and maintaining a strong presence on social media platforms such as Facebook, Instagram, Twitter, LinkedIn, and YouTube. It includes strategies such as content creation, community management, influencer partnerships, and social media advertising. Social media marketing helps businesses connect with their target audience, build brand awareness, engage with customers, and drive traffic to their website.
</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
            <div className="service-box purple">
              <i className="ri-discuss-line icon"></i>
              <h3>Search Engine Optimization (SEO)</h3>
              <p>SEO is the process of optimizing a website's content and structure to rank higher in search engine results pages (SERPs). It involves various techniques such as keyword research, on-page optimization, technical SEO, and link building. SEO helps businesses improve their organic visibility on search engines, drive more targeted traffic to their website, and establish their authority in their industry.</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
            <div className="service-box pink">
              <i className="ri-discuss-line icon"></i>
              <h3>Pay-Per-Click (PPC) Advertising</h3>
              <p>PPC advertising involves placing online ads that businesses only pay for when users click on them. Platforms like Google Ads and social media advertising platforms offer businesses the ability to create highly targeted ads based on keywords, demographics, interests, and online behavior. PPC advertising can provide businesses with immediate visibility, drive traffic to their website, and generate leads and sales.</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>
           <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="800">
            <div className="service-box orange">
              <i className="ri-discuss-line icon"></i>
              <h3>Responsive Design</h3>
              <p>Designing user interfaces (UI) for mobile and tablet devices like iPhone, iPad and Android is one of the most challenging task for every UI designer.Whether its Mobile Tablets or desktop i design interfaces fully Responsive</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="900">
            <div className="service-box green">
              <i className="ri-discuss-line icon"></i>
              <h3>PSD Design</h3>
              <p>Like a good looking website, now a days there had been a major demand for good looking Logo Design, banner Design,Business card.Wedding card Design using Adobe Photoshop CS6 That i Deliver on Fiverr within 24 hours.</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>
           <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="1000">
            <div className="service-box green">
              <i className="ri-discuss-line icon"></i>
              <h3>Online Reputation Management</h3>
              <p>Online reputation management involves monitoring and managing a business's online reputation, including customer reviews, ratings, and feedback. It includes strategies such as review management, brand monitoring, and customer feedback management. Online reputation management helps businesses maintain a positive online image, build trust with customers, and protect their brand reputation.

</p>
              <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
            </div>
          </div>

{/*

Latest Web Design Trends
Dark Mode: Dark mode is becoming increasingly popular, thanks to its energy-saving benefits and its ability to create a dramatic effect. It is also easier on the eyes, especially in low-light environments.

Minimalism: Minimalistic designs with clean lines, white space, and simple typography are gaining popularity. They offer a clean and organized look that is easy on the eyes and enhances the user experience.

Responsive Design: With the growing use of mobile devices, responsive design has become a must for websites. Responsive design ensures that your website looks good and functions well on all devices, including desktops, tablets, and smartphones.

3D Graphics: 3D graphics are becoming more prevalent in web design, thanks to the advancements in technology. They add depth and interactivity to your website, making it more engaging for users.

Motion Design: Motion design refers to the use of animations, transitions, and videos to enhance the user experience. They can be used to draw attention to specific elements, create a sense of continuity, and improve overall user engagement.





What are website design services?
Website design services are professional services that assist businesses in designing, developing, and maintaining their websites. These services include a range of tasks, such as website layout, graphics design, content creation, and website functionality. The ultimate goal of website design services is to create a website that aligns with the business's goals and caters to its target audience.

Benefits of website design services
There are several benefits of using website design services. Some of them include:

Professionalism
Professional website design services can ensure that your website looks and feels professional. A professionally designed website can instill confidence in potential customers and encourage them to interact with your business.

Customization
Website design services can tailor your website to meet the specific needs of your business. They can incorporate your brand's colors, logos, and imagery to create a cohesive brand identity.

Optimization
Website design services can optimize your website for search engines, making it easier for potential customers to find your business online. They can also ensure that your website is mobile-friendly and easy to navigate.

Time-saving
Designing a website can be time-consuming, especially for business owners who lack the technical expertise. By outsourcing website design services, businesses can save time and focus on their core business activities.

Choosing the right website design service
Choosing the right website design service is critical to the success of your online presence. Here are some factors to consider when choosing a website design service:

Experience
Look for a website design service that has experience in designing websites for businesses similar to yours. This can ensure that they have the necessary expertise to create a website that aligns with your business goals.

Portfolio
A website design service's portfolio can give you an idea of their capabilities and design style. Check their portfolio to see if they have experience designing websites that align with your business goals.

Communication
Effective communication is crucial when working with a website design service. Choose a service that has open lines of communication and is willing to work with you to achieve your goals.

Cost
Website design services vary in cost, depending on the complexity of the website and the service provider's expertise. Choose a service that offers a fair price and fits within your budget.



The website design process
The website design process typically involves several steps:

Discovery
The website design service will meet with the business owner to discuss their goals and requirements for the website. They will also analyze the target audience and competitors to determine the best approach to the design.

Design
The website design service will create a design concept for the website, including the layout, color scheme, and typography. The business owner will have the opportunity to provide feedback and make revisions.

Development
Once the design is finalized, the website design service will develop the website's functionality, including coding and testing. They will also optimize the website for search engines and mobile devices.

Launch
After the website has been developed and tested, it is ready to be launched. The website design service will ensure that the website is properly configured and functional.

Maintenance
Website design services also offer maintenance and support services to ensure that the website remains functional and up-to-date.
*/}


        </div>
      </div>
    </section>
    </>
  );
}
