const data =[
    {
        id:1,
        image:"assets/img/portfolio/1.avif",
        // title:"HTML Tutoril",
        // description:"Lorem Ipsum is simply dummy text. ",
        category:"Packaging",

    },
    {
        id:2,
        image:"assets/img/portfolio/2.avif",
        // title:"CSS Tutorial",
        // description:"Lorem Ipsum is simply dummy text. ",
        category:"Packaging",
       
    },
    {
        id:3,
        image:"assets/img/portfolio/3.avif",
        // title:"JS Tutorial",
        // description:"Lorem Ipsum is simply dummy text. ",
        category:"Frontend",
       
    },
    {
        id:4,
        image:"assets/img/portfolio/4.avif",
        // title:"React Js Tutorial",
        // description:"Lorem Ipsum is simply dummy text. ",
        category:"Frontend",
       
    },
    {
        id:5,
        image:"assets/img/portfolio/5.avif",
        // title:"Bootstrap Tutorial",
        // description:"Lorem Ipsum is simply dummy text. ",
        category:"Frontend",
       
    },
    {
        id:6,
        image:"assets/img/portfolio/6.avif",
        // title:"PHP Tutorial",
        // description:"Lorem Ipsum is simply dummy text. ",
        category:"Backend",
       
    },

   
]

export default data;