
export default function aboutus() {
  return (
    <>
    
   <section id="about" className="about">

      <div className="container" data-aos="fade-up">
        <div className="row gx-0">

          <div className="col-lg-7 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
              <h3>Who I Am</h3>
              <h2>Web Designer And Front-end Developer</h2>
              <p>I'm Full Stack Website Developer Since 2013, I have been designing beautiful and user friendly interfaces for mobile and web</p>
<p>I am able to work effectively in a team or on my Own. I pride myself on being able to use my knowledge and initiative to deliver high quality results to tight deadlines.</p>
<p>I have the enthusiasm and hunger to create things that are beautiful and functional.</p>
<p>
I enjoy a challenge and look for the opportunity to expand and develop my skill set for the future.
</p>
<p className="fh5co-sub to-animate">Creating is not just a job for me, it's my passion.</p>
              <div className="text-center text-lg-start">
                <a href="#" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-5 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img src={"../assets/img/ericssoncertificate.avif"} className="img-fluid" alt="" />
          </div>

        </div>
      </div>

    </section>
        <section id="values" className="values">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Values</h2>
          <p>Value-added service(VAS)</p>
        </header>

        <div className="row">

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="box">
              <img src="../assets/img/001.avif" className="img-fluid" alt="" />
              <h3>Web Apps Design</h3>
              <p>Whether its a One Page website or a full featured Travel Agency Website the focus on quality and individuality is never compromised. I regularly use modern techniques which makes easier.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div className="box">
              <img src="../assets/img/002.avif" className="img-fluid" alt="" />
              <h3>Responsive Design</h3>
              <p>Designing user interfaces (UI) for mobile and tablet devices like iPhone, iPad and Android is one of the most challenging task for every UI designer.Whether its Mobile Tablets or desktop i design interfaces fully Responsive</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
            <div className="box">
              <img src="../assets/img/003.avif" className="img-fluid" alt="" />
              <h3>Development</h3>
              <p>So now you got a good design, making it fully functional with the latest technology is mandatory aspect. I have 10yr+ Experience in Front end development on responsive framework like Bootstrap5 & React JS</p>
            </div>
          </div>

        </div>

      </div>

    </section>
    </>
  );
}
